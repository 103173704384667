//local url
//export const API_BASE_URL = "https://stagingattendance.krishnais.com:3001/v1";

//live url admin
     // export const API_BASE_URL = "https://api.krishnais.com/v1/";

//staging url
//  export const API_BASE_URL = "http://3.110.37.237:3004/v1/";
export const API_BASE_URL = "https://apistaging.krishnais.com/v1/";
 
